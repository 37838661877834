.course-identifiers {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
  .course-identifiers p {
    display: inline-block;
    margin-right: 5px;
  }
  