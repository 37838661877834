@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #f7f7ff;
}

.custom-container {
    width: 100%;
}
.custom-container .rmdp-input {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    padding: 5px;
}